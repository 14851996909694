import { Trans } from "@lingui/macro";
import { Layout, LayoutProps } from "antd";
// import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { memo } from "react";

import { Email, Phone, Location } from "../../assets/images";

import {
  StyledFooter,
  StyledLink,
  StyledTitle,
  StyledParagraph,
  AddressWrapper,
} from "./styles";

/**
 * Footer section
 */
function FooterComponent({
  className,
  ...props
}: LayoutProps): React.ReactElement {
  // const screen = useBreakpoint();

  return (
    <Layout.Footer className={className} {...props}>
      <StyledTitle level={3}>
        <Trans>Contact Us</Trans>
      </StyledTitle>
      <AddressWrapper xs={22} md={12} lg={8}>
        <StyledLink href="tel:+982188569083">
          <Phone />
          <Trans>021-88569083</Trans>
        </StyledLink>
        <StyledLink href="mailto:info@zardalu.dev">
          <Email />
          info@zardalu.dev
        </StyledLink>
        <StyledParagraph>
          <Location />
          <Trans>
            No 144, West Mirzaye Zeynali Street, North Sohrevardi Street,
            Tehran, Iran
          </Trans>
        </StyledParagraph>
      </AddressWrapper>
      {/* <iframe
        width={screen.lg ? "30%" : screen.md ? "40%" : "90%"}
        height="100%"
        frameBorder="0"
        scrolling="no"
        marginHeight={0}
        marginWidth={0}
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=35.77387997458298,%2051.3803+(Zardalu)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe> */}
    </Layout.Footer>
  );
}

export const Footer = StyledFooter.withComponent(memo(FooterComponent));
