import { Trans } from "@lingui/macro";
import { Rule } from "antd/lib/form";

export const requiredRule: Rule = {
  required: true,
  message: <Trans>This field Can't be empty.</Trans>,
};

export const justLettersRule: Rule = {
  pattern: /^\p{Letter}+(\p{Zs}+\p{Letter}+)*$/u,
  message: <Trans>Only letters are acceptable.</Trans>,
};

export const nameLengthRules: Rule[] = [
  {
    min: 3,
    message: <Trans>This field must be at least 3 letters.</Trans>,
  },
  {
    max: 20,
    message: <Trans>This field can't be longer than 20 letters.</Trans>,
  },
];

export const phoneNumberLengthRules: Rule[] = [
  {
    min: 11,
    message: <Trans>Phone number must be 11 digits exactly.</Trans>,
  },
  {
    max: 11,
    message: <Trans>Phone number must be 11 digits exactly.</Trans>,
  },
];

export const phoneNumberPrefixRule: Rule = {
  pattern: /^[0۰][9۹]/u,
  message: <Trans>Phone number must start with 09.</Trans>,
};

export const justNumberRules: Rule = {
  pattern: /^\p{Nd}+$/u,
  message: <Trans>Only digits are acceptable.</Trans>,
};
