import { Global, ThemeProvider } from "@emotion/react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { Layout, ConfigProvider } from "antd";
import { ConfigProviderProps } from "antd/es/config-provider";
import { Content } from "antd/es/layout/layout";
import React, { useCallback, useEffect, useState } from "react";

import { getLocale, LocaleContext, setLocale } from "./i18n/i18n";
import { theme } from "./lib/theme/default";
import {
  TopBar,
  Home,
  AboutUs,
  CultureAndValues,
  JoinUs,
  Footer,
} from "./screens";
import { globalStyles } from "./styles";
/**
 * Main page and component that includes all contents
 */
export function Application(): React.ReactElement | null {
  const [direction, setDirection] =
    useState<ConfigProviderProps["direction"]>();

  const [_theme, setTheme] = useState(theme);

  const changeLocale = useCallback(
    async (locale: string) => {
      await setLocale(locale);
      setDirection(locale === "fa" ? "rtl" : "ltr");
      setTheme({
        ..._theme,
        direction: locale === "fa" ? "rtl" : "ltr",
        language: locale,
      });
    },
    [_theme]
  );

  useEffect(() => {
    void changeLocale(getLocale());
  }, []);

  if (direction == null) {
    return null;
  }

  return (
    <ThemeProvider theme={_theme}>
      <LocaleContext.Provider value={changeLocale}>
        <React.StrictMode>
          <I18nProvider i18n={i18n}>
            <Global styles={globalStyles(_theme)} />
            <ConfigProvider direction={direction}>
              <Layout>
                <TopBar />
                <Content>
                  <Home />
                  <AboutUs />
                  <CultureAndValues />
                  <JoinUs />
                </Content>
                <Footer />
              </Layout>
            </ConfigProvider>
          </I18nProvider>
        </React.StrictMode>
      </LocaleContext.Provider>
    </ThemeProvider>
  );
}
