import { Trans, defineMessage } from "@lingui/macro";
import { Col, Row, RowProps, Typography } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { memo } from "react";

import { StyledAboutUs, StyledTitle, StyledParagraph } from "./styles";

const aboutUsItems = [
  {
    title: defineMessage({ message: "Who We Are" }),
    content: defineMessage({
      message:
        "Born in 2019, Zardalu is a forward-looking company focused on reaching specific, measurable and attainable results. Our company is committed to delivering high quality products rapidly.",
    }),
    id: 1,
  },
  {
    title: defineMessage({ message: "Our Mission" }),
    content: defineMessage({
      message:
        "To empower individuals and organizations to maximize their performance by employing superior level software in their procedures.",
    }),
    id: 2,
  },
  {
    title: defineMessage({ message: "What We Do" }),
    content: defineMessage({
      message:
        "Zardalu is helping companies that outsource their projects and want software that matches the requirements of the 21st century. We are assisting corporations to implement their products with high quality and to maintain them.",
    }),
    id: 3,
  },
  {
    title: defineMessage({ message: "Our Vision" }),
    content: defineMessage({
      message:
        "To be the company that best comprehends the world's needs and to fulfill those needs with our effort.",
    }),
    id: 4,
  },
];
/**
 * About us screen
 */
function AboutUsComponent({
  className,
  ...props
}: RowProps): React.ReactElement {
  const screen = useBreakpoint();

  return screen.lg ? (
    <Row className={className} {...props} id="AboutUs" align="middle">
      <Col span={8}>
        <StyledTitle level={3}>
          <Trans>About Us</Trans>
        </StyledTitle>
      </Col>
      <Col span={16}>
        <Row justify="space-around">
          {aboutUsItems.map((item) => (
            <Col span={10} key={item.id}>
              <Typography.Title level={4}>
                <Trans id={item.title.id} />
              </Typography.Title>
              <StyledParagraph>
                <Trans id={item.content.id} />
              </StyledParagraph>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  ) : (
    <Row className={className} {...props} id="AboutUs" justify="space-around">
      <Col span={24}>
        <StyledTitle>
          <Trans>About Us</Trans>
        </StyledTitle>
      </Col>
      {aboutUsItems.map((item) => (
        <Col span={20} key={item.id}>
          <Typography.Title level={4}>
            <Trans id={item.title.id} />
          </Typography.Title>
          <StyledParagraph>
            <Trans id={item.content.id} />
          </StyledParagraph>
        </Col>
      ))}
    </Row>
  );
}

export const AboutUs = StyledAboutUs.withComponent(memo(AboutUsComponent));
