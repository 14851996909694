import { t } from "@lingui/macro";

export const TARGET_EMAIL = "hr@zardalu.dev";
export const EMAIL_SUBJECT = t({
  message: "Apply to join Zardalu",
});

/**
 * Captalizes the given name
 */
function captalizeName(name: string): string {
  if (name.trim() === "") {
    return "";
  } else {
    return name
      .trim()
      .replace(/\s\s+/g, " ")
      .split(" ")
      .map((word) => `${word[0]?.toUpperCase()}${word?.slice(1)}`)
      .join(" ");
  }
}

/**
 * Gets the tempalte body of an email by giving some informations
 */
export function getEmailContent({
  forPreview = false,
  firstname,
  lastname,
  phone,
  description,
}: {
  forPreview?: boolean;
  firstname: string | undefined;
  lastname: string | undefined;
  phone: string | undefined;
  description: string | undefined;
}): string {
  const NEW_LINE = forPreview ? "<br />" : "%0D%0A";

  firstname = firstname
    ? captalizeName(firstname)
    : t({ message: "[Firstname]" });
  lastname = lastname ? captalizeName(lastname) : t({ message: "[Lastname]" });
  const fullname = `${firstname} ${lastname}`;
  phone = phone ? phone.padEnd(11, "X") : t({ message: "[Phone number]" });
  description = description ? description.trim() : "";
  if (description !== "") {
    description = `${NEW_LINE}${NEW_LINE}${description}`;
  }

  return t({
    message: `Dear Zardalu HR team,${NEW_LINE}${NEW_LINE}\
Attached is my resume and I'm looking forward to work with you.\
${description}${NEW_LINE}${NEW_LINE}\
You can reach me on ${phone}.${NEW_LINE}${NEW_LINE}\
${fullname}`,
  });
}
