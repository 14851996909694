import styled from "@emotion/styled";
import { Row, Typography } from "antd";

export const StyledAboutUs = styled(Row)`
  background: ${({ theme }): string => theme.color.mainContentBackground};
  padding: 80px 0;
`;

export const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 32px;
    text-align: center;
  }
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  text-align: justify;
  font-size: 16px;
`;
