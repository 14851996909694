import { render } from "react-dom";

import { Application } from "./Application";
import reportWebVitals from "./reportWebVitals";
import "./i18n/i18n.ts";

render(<Application />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
