import styled from "@emotion/styled";
import { Button, ButtonProps, Col, Row, Typography, Card } from "antd";

export const StyledJoinUs = styled(Row)`
  padding: 56px 0;
  margin: 0 !important;
`;

export const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 32px;
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const StyledColumn = styled(Col)`
  max-width: 500px;

  input,
  label,
  textarea {
    font-size: 16px;
  }
`;

export const SubmitButton = styled(Button)<ButtonProps>`
  background: ${({ theme }): string => theme.color.mainColor};
  border: none;
  margin-top: 16px;

  :focus {
    background: ${({ theme }): string => theme.color.highlighted};
  }

  :hover {
    background: ${({ theme }): string => theme.color.highlighted};
  }
`;

export const StyledCard = styled(Card)`
  align-self: flex-start;
  justify-content: flex-start;
  border: 1px solid ${({ theme }): string => theme.color.previewCardBorder};
  ${({ theme }) =>
    theme.direction === "rtl" ? "text-align: right;" : "text-align: left;"}
  word-wrap: break-word;
  text-overflow: ellipsis;

  h3 {
    text-align: center;
    color: ${({ theme }): string => theme.color.previewCardTitle};
  }

  hr {
    border: 1px solid ${({ theme }): string => theme.color.breakLine};
  }

  ul > li > span {
    cursor: default;
  }
`;
