import styled from "@emotion/styled";
import { Row, Typography, Col } from "antd";

import img from "../../assets/images/backgroung.jpg";

export const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 32px;
    color: ${({ theme }): string => theme.color.mainColor};
    text-align: center;
  }
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  font-size: 20px;
  text-align: center;

  @media (max-width: 992px) {
    color: ${({ theme }): string => theme.color.white};
  }
`;

export const HomeImage = styled(Col)`
  height: 600px;
  background: url(${img}) no-repeat;
  background-size: cover;
`;

export const StyledHome = styled(Row)`
  height: 600px;
  background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
    url(${img});
  background-size: cover;

  @media (min-width: 992px) {
    background: initial;
    height: initial;
  }
`;

export const StyledCol = styled(Col)`
  max-width: 896px;

  svg {
    transform: rotate(180deg);
    width: 40px;
    height: 40px;
    margin: 8px;
    fill: ${({ theme }): string => theme.color.mainColor};
  }
`;
