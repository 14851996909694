import styled from "@emotion/styled";
import { Col, Row, Typography } from "antd";

export const StyledCultureAndValues = styled(Row)`
  background: ${({ theme }): string => theme.color.white};
  padding: 80px 0;
`;

export const StyledCol = styled(Col)`
  padding: 24px;
  margin: 24px;
  max-width: 384px;
  min-height: 384px;
  background: ${({ theme }): string => theme.color.CardBackground};

  svg {
    display: block;
    margin: 0 auto;
    width: 160px;
    height: 160px;
    fill: ${({ theme }): string => theme.color.iconColor};
  }
`;

export const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }): string => theme.color.black};
    font-size: 32px;
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const SubTitle = styled(Typography.Title)`
  margin-top: 32px;
`;

export const Content = styled(Typography.Paragraph)`
  font-size: 16px;
`;
