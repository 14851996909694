import { Trans, defineMessage } from "@lingui/macro";
import { Row, Col, RowProps } from "antd";
import React, { memo } from "react";

import {
  Transparency,
  Standard,
  OrganicGrowth,
  LifeBalance,
  FreedomToFail,
} from "../../assets/images";

import {
  StyledCultureAndValues,
  StyledTitle,
  SubTitle,
  Content,
  StyledCol,
} from "./styles";

const cultureAndValues = [
  {
    svg: Transparency,
    title: defineMessage({ message: "Transparency" }),
    content: defineMessage({
      message:
        "Clear and open communication between all parties, while maintaining confidentiality.",
    }),
    id: 1,
  },
  {
    svg: Standard,
    title: defineMessage({ message: "High Standards" }),
    content: defineMessage({ message: "Good enough is not enough!" }),
    id: 2,
  },
  {
    svg: LifeBalance,
    title: defineMessage({ message: "Work-Life Balance" }),
    content: defineMessage({
      message: "To keep your balance you need to ride both sides.",
    }),
    id: 3,
  },
  {
    svg: FreedomToFail,
    title: defineMessage({ message: "Freedom to Fail" }),
    content: defineMessage({
      message: "You can make a mistake wise enough to not repeat it twice.",
    }),
    id: 4,
  },
  {
    svg: OrganicGrowth,
    title: defineMessage({ message: "Organic Growth" }),
    content: defineMessage({
      message:
        "It is ok to move at snail’s pace but do not forget your commitment to growth!",
    }),
    id: 5,
  },
];
/**
 * Culture and culture-and-values screen
 */
function CultureAndValuesComponent({
  className,
  ...props
}: RowProps): React.ReactElement {
  return (
    <Row
      className={className}
      {...props}
      id="cultureAndValues"
      justify="center"
    >
      <Col span={24}>
        <StyledTitle level={3}>
          <Trans>Culture and Values</Trans>
        </StyledTitle>
      </Col>
      {cultureAndValues.map((value) => (
        <StyledCol xs={22} sm={10} md={10} xl={7} xxl={4} key={value.id}>
          <value.svg />
          <SubTitle level={4}>
            <Trans id={value.title.id} />
          </SubTitle>
          <Content>
            <Trans id={value.content.id} />
          </Content>
        </StyledCol>
      ))}
    </Row>
  );
}

export const CultureAndValues = StyledCultureAndValues.withComponent(
  memo(CultureAndValuesComponent)
);
