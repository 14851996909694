import { Trans } from "@lingui/macro";
import { Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { memo } from "react";

import { DoubleQuotes } from "../../assets/images";

import {
  StyledHome,
  StyledTitle,
  StyledParagraph,
  HomeImage,
  StyledCol,
} from "./styles";

/**
 * Home screen to introduce the company
 */
function HomeComponent({
  className,
}: {
  className?: string;
}): React.ReactElement {
  const screen = useBreakpoint();

  return (
    <Row className={className} align="middle" justify="space-between" id="home">
      <StyledCol
        xs={{ span: 22, offset: 1 }}
        lg={{ span: 10, offset: 1 }}
        xxl={{ span: 8, offset: 2 }}
      >
        <DoubleQuotes />
        <StyledTitle level={2}>
          <Trans>Raise your standards and we will rise up to meet them!</Trans>
        </StyledTitle>
        <StyledParagraph>
          <Trans>
            We are a software development company with high standards. We do not
            settle for good enough!
            <br />
            We accomplish your goals through a process all parties enjoy.
          </Trans>
        </StyledParagraph>
      </StyledCol>
      {screen.lg ? <HomeImage span={12} /> : null}
    </Row>
  );
}

export const Home = StyledHome.withComponent(memo(HomeComponent));
