import "antd/dist/antd.min.css";
import { css, SerializedStyles, Theme } from "@emotion/react";

import { fontStyles } from "./assets/fonts/font";

/**
 * Global styles
 */
export function globalStyles(theme: Theme): SerializedStyles {
  return css`
    @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;1,100;1,500&display=swap");

    ${fontStyles};

    html,
    body {
      min-height: 100vh;
      scroll-behavior: smooth;
      font-family: ${theme.language === "fa"
        ? `"Vazir", sans-serif`
        : `"Poppins", sans-serif`};
    }
  `;
}
