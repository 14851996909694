import styled from "@emotion/styled";
import { Layout, Typography, Col } from "antd";

export const StyledFooter = styled(Layout.Footer)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;

  &.ant-layout-footer {
    color: ${({ theme }): string => theme.color.white};
    padding: 56px 0;
    background: ${({ theme }): string => theme.color.footerBackground};
  }
`;

export const AddressWrapper = styled(Col)`
  svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }): string => theme.color.white};
    ${({ theme }): string =>
      theme.direction === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;"}
  }
`;

export const StyledLink = styled(Typography.Link)`
  &.ant-typography {
    color: ${({ theme }): string => theme.color.white};
    display: flex;
    margin-bottom: 16px;

    :hover {
      color: ${({ theme }): string => theme.color.mainColor};

      svg {
        fill: ${({ theme }): string => theme.color.mainColor};
      }
    }

    :focus {
      color: ${({ theme }): string => theme.color.mainColor};

      svg {
        fill: ${({ theme }): string => theme.color.mainColor};
      }
    }
  }
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  &.ant-typography {
    color: ${({ theme }): string => theme.color.white};
    margin-bottom: 16px;
  }
`;

export const StyledTitle = styled(Typography.Title)`
  width: 100%;
  &.ant-typography {
    color: ${({ theme }): string => theme.color.white};
    font-size: 32px;
    text-align: center;
    margin-bottom: 32px;
  }
`;
