import styled from "@emotion/styled";
import { Layout, Typography, Menu, Row } from "antd";

export const StyledTopBar = styled(Layout.Header)`
  padding: 0;
  background: ${({ theme }): string => theme.color.topBarBackground};
`;

export const StyledMenu = styled(Menu)`
  justify-content: flex-end;
  background: ${({ theme }): string => theme.color.topBarBackground};
  border: none;
  box-shadow: none;
`;

export const StyledSubMenu = styled(Menu.SubMenu)`
  &&& {
    &.ant-menu-submenu,
    .ant-menu-submenu-title {
      :hover {
        color: ${({ theme }): string => theme.color.black};
        font-weight: 800;

        :after {
          border-color: ${({ theme }): string => theme.color.black};
        }
      }
    }

    .ant-menu-submenu-arrow {
      :hover {
        color: red;
      }
    }

    &.ant-menu-submenu-open {
      color: ${({ theme }): string => theme.color.black};
      font-weight: 800;

      :after {
        border-color: ${({ theme }): string => theme.color.black};
      }
    }

    &.ant-menu-submenu-selected {
      color: ${({ theme }): string => theme.color.mainColor};

      :after {
        border-color: ${({ theme }): string => theme.color.mainColor};
      }
    }
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  &&&& {
    &.ant-menu-item-active {
      font-weight: 800;

      :after {
        border-bottom: 2px solid ${({ theme }): string => theme.color.black};
      }
    }

    &.ant-menu-submenu-selected {
      color: ${({ theme }): string => theme.color.mainColor};
    }

    &.ant-menu-item-selected {
      background: unset;
      color: ${({ theme }): string => theme.color.mainColor};
      font-weight: 800;

      a {
        color: ${({ theme }): string => theme.color.mainColor};
        font-weight: 800;
      }
      :after {
        border-color: ${({ theme }): string => theme.color.mainColor};
      }
    }

    :hover {
      color: ${({ theme }): string => theme.color.black};
      font-weight: 800;

      :after {
        border-color: ${({ theme }): string => theme.color.black};
      }
    }

    :active {
      background: unset;
    }

    a {
      :hover {
        color: unset;
      }
    }
  }
`;

export const LogoContainer = styled(Row)`
  ${({ theme }) => (theme.direction === "ltr" ? "float:left;" : "float:right;")}
  width: 200px;
  height: 100%;
  background: ${({ theme }): string => theme.color.topBarBackground};
  ${({ theme }) =>
    theme.direction === "rtl" ? "padding-right: 24px;" : "padding-left: 24px;"}

  svg {
    width: 40px;
    height: 40px;
    stroke: ${({ theme }): string => theme.color.mainColor};
    stroke-width: 15;
    ${({ theme }) =>
      theme.direction === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;"}

    path {
      fill: ${({ theme }): string => theme.color.mainColor};
    }
  }
`;

export const ZardaluHeading = styled(Typography.Title)`
  &.ant-typography {
    color: ${({ theme }): string => theme.color.mainColor};
    font-size: 32px;
    user-select: none;
    font-family: "American Typewriter";
    margin-bottom: 0;
  }
`;
