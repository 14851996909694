import { Trans, t } from "@lingui/macro";
import { Form, Input, RowProps, Alert, Col } from "antd";
import React, { memo, useCallback, useMemo, useState } from "react";

import {
  StyledJoinUs,
  StyledTitle,
  StyledColumn,
  SubmitButton,
  StyledCard,
} from "./styles";
import {
  getEmailContent,
  TARGET_EMAIL,
  EMAIL_SUBJECT,
} from "./utilities/email";
import {
  justLettersRule,
  nameLengthRules,
  requiredRule,
  phoneNumberLengthRules,
  phoneNumberPrefixRule,
  justNumberRules,
} from "./utilities/rules";

interface FormValues {
  firstname: string | undefined;
  lastname: string | undefined;
  phone: string | undefined;
  description: string | undefined;
}
const formInitializer: FormValues = {
  firstname: "",
  lastname: "",
  phone: "",
  description: "",
};

/**
 * Culture and culture-and-values screen
 */
function JoinUsComponent({
  className,
  ...props
}: RowProps): React.ReactElement {
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState(formInitializer);

  const handleFormValuesChange = useCallback((_, values: FormValues) => {
    setFormFields(values);
  }, []);

  const submit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      try {
        await form.validateFields(["firstname", "lastname", "phone"]);
        const emailContent = getEmailContent(formFields);

        window.open(
          `mailto:${TARGET_EMAIL}?subject=${EMAIL_SUBJECT}&body=${emailContent}`
        );
      } catch (error) {
        // Do nothings
      }
    },
    [formFields]
  );

  const nameRules = useMemo(
    () => [requiredRule, justLettersRule, ...nameLengthRules],
    []
  );

  const phoneNumberRules = useMemo(
    () => [
      requiredRule,
      phoneNumberPrefixRule,
      justNumberRules,
      ...phoneNumberLengthRules,
    ],
    []
  );

  return (
    <StyledJoinUs
      id="joinUs"
      className={className}
      {...props}
      justify="center"
      gutter={[
        { xs: 0, sm: 0, md: 0, lg: 16 },
        { xs: 16, sm: 16, md: 24, lg: 0 },
      ]}
    >
      <Col span={24}>
        <StyledTitle level={3}>
          <Trans>Join Us</Trans>
        </StyledTitle>
      </Col>
      <StyledColumn xs={20} sm={18} md={14} lg={10}>
        <Form
          onValuesChange={handleFormValuesChange}
          form={form}
          initialValues={formInitializer}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <Form.Item
            name="firstname"
            label={t({ message: "Firstname" })}
            rules={nameRules}
            validateTrigger="onBlur"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="lastname"
            label={t({ message: "Lastname" })}
            rules={nameRules}
            validateTrigger="onBlur"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t({ message: "Phone" })}
            rules={phoneNumberRules}
            validateTrigger="onBlur"
          >
            <Input
              type="tel"
              placeholder={t({ message: "Example: 09123456789" })}
            />
          </Form.Item>
          <Form.Item name="description" label={t({ message: "Description" })}>
            <Input.TextArea rows={10} />
          </Form.Item>
        </Form>
        <Alert
          showIcon
          type="info"
          message={t({
            message:
              "Please attach you resume file with the prepared email in the next step.",
          })}
        />
      </StyledColumn>
      <StyledColumn xs={20} sm={18} md={14} lg={10}>
        <StyledCard
          title={
            <h3>
              <Trans>Email preview</Trans>
            </h3>
          }
          size="small"
          actions={[
            <SubmitButton size="large" type="primary" onClick={submit}>
              <Trans>Send Email</Trans>
            </SubmitButton>,
          ]}
        >
          <h4>
            <Trans>To:</Trans> {TARGET_EMAIL}
          </h4>
          <hr />
          <h4>
            <Trans>Subject:</Trans> <Trans id={EMAIL_SUBJECT} />
          </h4>
          <hr />
          <p
            dangerouslySetInnerHTML={{
              __html: getEmailContent({
                ...formFields,
                forPreview: true,
              }),
            }}
          />
        </StyledCard>
      </StyledColumn>
    </StyledJoinUs>
  );
}

export const JoinUs = StyledJoinUs.withComponent(memo(JoinUsComponent));
