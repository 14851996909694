import { Theme } from "@emotion/react";

import { getLocale } from "../../i18n/i18n";

const colorPalette = {
  primary: "#d79904",
  secondary: "#ebac15",
  tertiary: "#d4af37",
  white: "#ffffff",
  surface: "#dde4ed",
  cover: "#d6d6d6",
  pale: "#ededed",
  translucent: "#666666",
  chromatic: "#424242",
  black: "#000000",
  previewCardColor: "#d9d9d9",
};

export const theme: Theme = {
  color: {
    mainColor: colorPalette.primary,
    highlighted: colorPalette.secondary,
    dashedBorderHighlighted: colorPalette.tertiary,
    white: colorPalette.white,
    black: colorPalette.black,
    footerBackground: colorPalette.chromatic,
    topBarBackground: colorPalette.cover,
    mainContentBackground: colorPalette.surface,
    CardBackground: colorPalette.pale,
    iconColor: colorPalette.translucent,
    previewCardBorder: colorPalette.previewCardColor,
    previewCardTitle: colorPalette.translucent,
    breakLine: colorPalette.previewCardColor,
  },
  direction: getLocale() === "fa" ? "rtl" : "ltr",
  language: getLocale(),
};
