import { defineMessage, Trans } from "@lingui/macro";
import { Affix, Layout, LayoutProps } from "antd";
import React, { memo, useCallback, useContext } from "react";

import { Logo } from "../../assets/images";
import { LocaleContext } from "../../i18n/i18n";

import {
  StyledTopBar,
  ZardaluHeading,
  LogoContainer,
  StyledMenu,
  StyledMenuItem,
  StyledSubMenu,
} from "./styles";

const menuItems = [
  {
    title: defineMessage({ message: "Language" }),
    id: 1,
    subMenuItems: [
      {
        id: 12,
        nativeName: "English",
        language: "en",
      },
      {
        id: 13,
        nativeName: "فارسی",
        language: "fa",
      },
    ],
  },
  {
    title: defineMessage({ message: "Join Us" }),
    linkAddress: "#joinUs",
    id: 2,
  },
  {
    title: defineMessage({ message: "Culture and Values" }),
    linkAddress: "#cultureAndValues",
    id: 3,
  },
  {
    title: defineMessage({ message: "About Us" }),
    linkAddress: "#AboutUs",
    id: 4,
  },
  {
    title: defineMessage({ message: "Home" }),
    linkAddress: "#home",
    id: 5,
  },
];

/**
 * Navigation bar at the top of site
 */
function TopBarComponent({
  className,
  ...props
}: LayoutProps): React.ReactElement {
  const changeLocale = useContext(LocaleContext);

  const handleClick = useCallback((language) => {
    changeLocale(language);
  }, []);

  return (
    <Affix>
      <Layout.Header className={className} {...props}>
        <LogoContainer align="middle">
          <Logo />
          <ZardaluHeading level={1}>
            <Trans>Zardalu</Trans>
          </ZardaluHeading>
        </LogoContainer>
        <StyledMenu mode="horizontal">
          {menuItems.map((menuItem) =>
            menuItem.subMenuItems ? (
              <StyledSubMenu
                key={menuItem.id}
                title={<Trans id={menuItem.title.id} />}
              >
                {menuItem.subMenuItems.map((subItem) => (
                  <StyledMenuItem
                    key={subItem.id}
                    onClick={() => handleClick(subItem.language)}
                  >
                    {subItem.nativeName}
                  </StyledMenuItem>
                ))}
              </StyledSubMenu>
            ) : (
              <StyledMenuItem key={menuItem.id}>
                <a href={menuItem.linkAddress}>
                  <Trans id={menuItem.title.id} />
                </a>
              </StyledMenuItem>
            )
          )}
        </StyledMenu>
      </Layout.Header>
    </Affix>
  );
}

export const TopBar = StyledTopBar.withComponent(memo(TopBarComponent));
