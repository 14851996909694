import { i18n, Messages, Locale } from "@lingui/core";
import { detect, fromStorage, fromNavigator } from "@lingui/detect-locale";
import { en, fa } from "make-plural/plurals";
import { createContext } from "react";

i18n.loadLocaleData("en", { plurals: en });
i18n.loadLocaleData("fa", { plurals: fa });

export const LOCALES = {
  en: "English",
  fa: "فارسی",
};
export const DEFAULT_LOCALE = "en";

/**
 * Checks whether `locale` is among valid locales
 */
function isLocaleValid(locale: string | null): locale is Locale {
  return locale != null && locale in LOCALES;
}

/**
 * Tries to read locale from
 * 1. `LocalStorage`
 * 2. Browser
 * If none of the above sources are available, it will return the default locale.
 */
export function getLocale(): Locale {
  const detectedLocale = detect(fromStorage("language"), fromNavigator());

  return isLocaleValid(detectedLocale) ? detectedLocale : DEFAULT_LOCALE;
}

/**
 * Will do a dynamic import of the messages catalog of the provided `locale`
 */
export async function setLocale(locale: Locale): Promise<void> {
  const { messages } = (await import(`../locales/${locale}/messages`)) as {
    messages: Messages;
  };
  i18n.load(locale, messages);
  i18n.activate(locale);
  window.localStorage.setItem("language", locale);
}

export const LocaleContext = createContext<(locale: Locale) => void>(
  () => undefined
);
